import * as SheetPrimitive from '@radix-ui/react-dialog';
import { Link, NavLink, useSubmit } from '@remix-run/react';
import { ArrowRight } from 'lucide-react';
import React from 'react';
import { useWindowSize } from 'usehooks-ts';
import {
  APPLE_STORE_LINK,
  EMAIL,
  EXPERTS_APP_LINK,
  GOOGLE_PLAY_STORE_LINK,
  HOUSESHARING_LINK,
  PHONE,
  PHONE_INTERNATIONAL,
} from '~/constants';
import { useTranslation } from '~/i18n';
import type { ViewerSession } from '~/utils/auth.server';
import { cn, getResponsiveFontSize } from '~/utils/cn';
import { Coin } from './Coin';
import { ArrowDropdown } from './icons/ArrowDropdown';
import { Close } from './icons/Close';
import { Menu } from './icons/Menu';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from './ui/Accordion';
import { Avatar, AvatarFallback, AvatarImage } from './ui/Avatar';
import { Button, buttonVariants } from './ui/Button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from './ui/Dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from './ui/Dropdown';
import { Image as ImageComp } from './ui/Image';
import { Logo } from './ui/Logo';
import { Popover, PopoverContent, PopoverTrigger } from './ui/Popover';
import {
  Sheet,
  SheetClose,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
  sheetVariants,
} from './ui/Sheet';
import { Skeleton } from './ui/Skeleton';
import { HeadingS, HeadingXS, textVariants } from './ui/Typography';

type HeaderContextValue = {
  submenu: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
};

const HeaderContext = React.createContext<HeaderContextValue>({} as HeaderContextValue);

export function Header(props: React.ComponentProps<'header'>) {
  const submenu = React.useState(false);
  const isSubmenuOpen = !!submenu[0];

  const preloadImages = React.useCallback(() => {
    const images = [
      '/images/nav-activities-club.jpeg',
      '/images/nav-house-sharing.jpeg',
      '/images/nav-partners.jpeg',
    ];

    images.forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  }, []);

  return (
    <>
      <div
        style={{ backfaceVisibility: 'hidden', perspective: 1000 }}
        className={cn(
          'fixed inset-x-0 top-0 z-10 bg-black/50 opacity-0 transition-all duration-300 ease-linear',
          {
            'bottom-0 opacity-100': isSubmenuOpen,
          },
        )}
      />
      <HeaderContext.Provider value={{ submenu }}>
        <div className="sticky top-0 z-40 w-full border-b-2 backdrop-blur-md supports-[backdrop-filter]:bg-background/60">
          <header
            {...props}
            onMouseEnter={preloadImages}
            id="HEADER"
            style={{ backfaceVisibility: 'hidden', perspective: 1000 }}
            className={cn(
              'mx-auto flex h-[88px] w-full max-w-screen-2xl items-center justify-between px-6 transition-colors ease-in-out',
              { '!bg-white': isSubmenuOpen },
              props.className,
            )}
          />
        </div>
      </HeaderContext.Provider>
    </>
  );
}

export function HeaderNav({
  viewer,
  loading,
  ...props
}: React.ComponentProps<'nav'> & {
  viewer?: ViewerSession | null;
  loading?: boolean;
}) {
  const submit = useSubmit();
  const { t } = useTranslation();
  const submenu = React.useContext(HeaderContext).submenu;

  return (
    <nav {...props}>
      <ul className="hidden items-center gap-10 xl:flex">
        {viewer ? (
          <>
            <li>
              <HeaderNavLink to="/">{t('nav-home')}</HeaderNavLink>
            </li>
            <li>
              <HeaderNavLink to="/activites-senior">{t('nav-activities')}</HeaderNavLink>
            </li>
            <li>
              <DropdownMenu>
                <DropdownMenuTrigger
                  className={cn(
                    buttonVariants({ variant: 'link' }),
                    props.className,
                    'px-0 no-underline',
                  )}
                  style={{
                    fontSize: getResponsiveFontSize({
                      minFontSize: 1,
                      maxFontSize: 1.25,
                    }),
                  }}
                >
                  {t('nav-other-services')}
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem asChild>
                    <Link to={HOUSESHARING_LINK} target="__blank">
                      {t('house-sharing')}
                    </Link>
                  </DropdownMenuItem>
                  <DropdownMenuItem asChild>
                    <Link to={EXPERTS_APP_LINK} target="__blank">
                      {t('discount')}
                    </Link>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </li>
          </>
        ) : (
          <>
            <DesktopServicesNavItem />
            <li onMouseEnter={() => submenu[1](false)}>
              <HeaderNavLink to="/#how-it-works">{t('nav-how-it-works')}</HeaderNavLink>
            </li>
            <li>
              <DropdownMenu>
                <DropdownMenuTrigger
                  className={cn(
                    buttonVariants({ variant: 'link' }),
                    props.className,
                    'px-0 no-underline',
                  )}
                  style={{
                    fontSize: getResponsiveFontSize({
                      minFontSize: 1,
                      maxFontSize: 1.25,
                    }),
                  }}
                >
                  {t('nav-download-app')}
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem asChild>
                    <Link to={APPLE_STORE_LINK} target="__blank">
                      <svg
                        className="size-6"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlSpace="preserve"
                        viewBox="0 0 1024 1024"
                      >
                        <path d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z" />
                      </svg>
                      App Store
                    </Link>
                  </DropdownMenuItem>
                  <DropdownMenuItem asChild>
                    <Link to={GOOGLE_PLAY_STORE_LINK} target="__blank">
                      <svg
                        viewBox="0 0 40 40"
                        xmlns="http://www.w3.org/2000/svg"
                        className="size-6"
                      >
                        <path fill="none" d="M0,0h40v40H0V0z" />
                        <g>
                          <path
                            d="M19.7,19.2L4.3,35.3c0,0,0,0,0,0c0.5,1.7,2.1,3,4,3c0.8,0,1.5-0.2,2.1-0.6l0,0l17.4-9.9L19.7,19.2z"
                            fill="#EA4335"
                          />
                          <path
                            d="M35.3,16.4L35.3,16.4l-7.5-4.3l-8.4,7.4l8.5,8.3l7.5-4.2c1.3-0.7,2.2-2.1,2.2-3.6C37.5,18.5,36.6,17.1,35.3,16.4z"
                            fill="#FBBC04"
                          />
                          <path
                            d="M4.3,4.7C4.2,5,4.2,5.4,4.2,5.8v28.5c0,0.4,0,0.7,0.1,1.1l16-15.7L4.3,4.7z"
                            fill="#4285F4"
                          />
                          <path
                            d="M19.8,20l8-7.9L10.5,2.3C9.9,1.9,9.1,1.7,8.3,1.7c-1.9,0-3.6,1.3-4,3c0,0,0,0,0,0L19.8,20z"
                            fill="#34A853"
                          />
                        </g>
                      </svg>
                      Google Play
                    </Link>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </li>
          </>
        )}

        {viewer || loading ? (
          loading ? (
            <li className="flex items-center gap-2">
              <Skeleton className="size-12 rounded-full" />
              <Skeleton className="h-5 w-24" />
            </li>
          ) : (
            <li className="flex items-center gap-4">
              <ViewerCoins viewer={viewer as ViewerSession} />

              <DropdownMenu>
                <DropdownMenuTrigger
                  className={cn(
                    'flex items-center gap-2 font-bold outline-none',
                    textVariants({ size: 'l' }),
                  )}
                >
                  <Avatar className="size-12">
                    <AvatarImage
                      src={viewer?.profile?.avatar?.url || undefined}
                      alt={`${viewer?.profile?.firstName}`}
                      className="object-cover object-center"
                    />
                    <AvatarFallback className="border-2">
                      {viewer?.profile?.firstName?.charAt(0)}
                      {viewer?.profile?.lastName?.charAt(0)}
                    </AvatarFallback>
                  </Avatar>
                  {viewer?.profile?.firstName}

                  <ArrowDropdown className="size-5" />
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem asChild>
                    <Link to={'/users/' + viewer?.id} prefetch="render">
                      {t('nav-my-profile')}
                    </Link>
                  </DropdownMenuItem>
                  <DropdownMenuItem asChild>
                    <Link to="/account" prefetch="render">
                      {t('nav-my-account')}
                    </Link>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    className="text-red"
                    onClick={() => submit(null, { method: 'post', action: '/logout' })}
                  >
                    {t('nav-logout')}
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </li>
          )
        ) : (
          <li>
            <ul className="flex items-center gap-6">
              <li>
                <HeaderNavLink to="/signup" prefetch="intent">
                  {t('nav-signup')}
                </HeaderNavLink>
              </li>
              <li>
                <Link
                  to="/login"
                  className={buttonVariants({ className: 'min-w-36' })}
                  prefetch="intent"
                >
                  {t('nav-login')}
                </Link>
              </li>
            </ul>
          </li>
        )}
      </ul>

      {/* Mobile Menu */}
      <Sheet>
        <SheetTrigger asChild>
          <button
            type="button"
            className="block rounded-lg bg-white p-[10px] xl:hidden"
            aria-labelledby="Menu"
          >
            <Menu className="size-5" />
          </button>
        </SheetTrigger>
        <SheetPrimitive.Portal>
          <SheetPrimitive.Content
            className={cn(
              sheetVariants({
                side: 'right',
                className: 'w-screen bg-white',
              }),
              'overflow-y-auto',
            )}
          >
            <SheetHeader className="h-[72px]">
              <SheetTitle className="flex items-center justify-between">
                <Link to="/" prefetch="intent" aria-label="Club Colette">
                  <Logo />
                </Link>

                <SheetPrimitive.Close asChild>
                  <button type="button" className="block rounded-lg bg-surface p-[10px] md:hidden">
                    <Close className="size-5" />
                  </button>
                </SheetPrimitive.Close>
              </SheetTitle>
            </SheetHeader>
            <ul className="flex flex-col gap-12">
              {viewer ? (
                <>
                  <li>
                    <SheetClose asChild>
                      <HeaderNavLink to="/">{t('nav-home')}</HeaderNavLink>
                    </SheetClose>
                  </li>
                  <li>
                    <SheetClose asChild>
                      <HeaderNavLink to="/activites-senior">{t('nav-activities')}</HeaderNavLink>
                    </SheetClose>
                  </li>
                  <li>
                    <Accordion type="single" collapsible className="w-full">
                      <AccordionItem value="app" className="m-0 border-none">
                        <AccordionTrigger
                          className={cn(
                            buttonVariants({ variant: 'link' }),
                            'justify-start px-0 no-underline',
                          )}
                          style={{
                            fontSize: getResponsiveFontSize({
                              minFontSize: 1,
                              maxFontSize: 1.25,
                            }),
                          }}
                        >
                          {t('nav-other-services')}
                        </AccordionTrigger>
                        <AccordionContent className="flex flex-col items-start gap-y-8 pb-0 pl-4 pt-10">
                          <SheetClose asChild>
                            <HeaderNavLink to={HOUSESHARING_LINK} target="__blank">
                              {t('house-sharing')}
                            </HeaderNavLink>
                          </SheetClose>

                          <SheetClose asChild>
                            <HeaderNavLink to={EXPERTS_APP_LINK} target="__blank">
                              {t('discount')}
                            </HeaderNavLink>
                          </SheetClose>
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  </li>
                  <li>
                    <ViewerCoins viewer={viewer as ViewerSession} />
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <MobileServicesNavItem />
                  </li>
                  <li>
                    <SheetClose asChild>
                      <HeaderNavLink to="/#how-it-works">{t('nav-how-it-works')}</HeaderNavLink>
                    </SheetClose>
                  </li>
                  <li>
                    <Accordion type="single" collapsible className="w-full">
                      <AccordionItem value="app" className="m-0 border-none">
                        <AccordionTrigger
                          className={cn(
                            buttonVariants({ variant: 'link' }),
                            'justify-start px-0 no-underline',
                          )}
                          style={{
                            fontSize: getResponsiveFontSize({
                              minFontSize: 1,
                              maxFontSize: 1.25,
                            }),
                          }}
                        >
                          {t('nav-download-app')}
                        </AccordionTrigger>
                        <AccordionContent className="flex flex-col gap-y-8 pb-0 pl-4 pt-10">
                          <Link
                            to={APPLE_STORE_LINK}
                            target="__blank"
                            className={buttonVariants({
                              variant: 'link',
                              class: 'px-0 justify-start no-underline self-start',
                            })}
                            aria-label={t('nav-app-download-apple')}
                          >
                            <svg
                              className="size-6"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlSpace="preserve"
                              viewBox="0 0 1024 1024"
                            >
                              <path d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z" />
                            </svg>
                            App Store
                          </Link>

                          <Link
                            to={GOOGLE_PLAY_STORE_LINK}
                            target="__blank"
                            className={buttonVariants({
                              variant: 'link',
                              class: 'px-0 justify-start no-underline self-start',
                            })}
                            aria-label={t('nav-app-download-google')}
                          >
                            <svg
                              viewBox="0 0 40 40"
                              xmlns="http://www.w3.org/2000/svg"
                              className="size-6"
                            >
                              <path fill="none" d="M0,0h40v40H0V0z" />
                              <g>
                                <path
                                  d="M19.7,19.2L4.3,35.3c0,0,0,0,0,0c0.5,1.7,2.1,3,4,3c0.8,0,1.5-0.2,2.1-0.6l0,0l17.4-9.9L19.7,19.2z"
                                  fill="#EA4335"
                                />
                                <path
                                  d="M35.3,16.4L35.3,16.4l-7.5-4.3l-8.4,7.4l8.5,8.3l7.5-4.2c1.3-0.7,2.2-2.1,2.2-3.6C37.5,18.5,36.6,17.1,35.3,16.4z"
                                  fill="#FBBC04"
                                />
                                <path
                                  d="M4.3,4.7C4.2,5,4.2,5.4,4.2,5.8v28.5c0,0.4,0,0.7,0.1,1.1l16-15.7L4.3,4.7z"
                                  fill="#4285F4"
                                />
                                <path
                                  d="M19.8,20l8-7.9L10.5,2.3C9.9,1.9,9.1,1.7,8.3,1.7c-1.9,0-3.6,1.3-4,3c0,0,0,0,0,0L19.8,20z"
                                  fill="#34A853"
                                />
                              </g>
                            </svg>
                            Google Play
                          </Link>
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  </li>
                </>
              )}
            </ul>
            <SheetFooter className="mt-20 gap-10">
              {viewer ? (
                <Accordion type="single" collapsible className="w-full">
                  <AccordionItem value="services" className="border-none">
                    <AccordionTrigger
                      className="items-center justify-start gap-4 pb-0 text-base font-medium md:text-xl"
                      style={{
                        fontSize: getResponsiveFontSize({
                          minFontSize: 1,
                          maxFontSize: 1.25,
                        }),
                      }}
                    >
                      <Avatar className="size-10">
                        <AvatarImage
                          src={viewer?.profile?.avatar?.url || undefined}
                          alt={`${viewer?.profile?.firstName}`}
                          className="object-cover object-center"
                        />
                        <AvatarFallback>
                          {viewer?.profile?.firstName?.charAt(0)}
                          {viewer?.profile?.lastName?.charAt(0)}
                        </AvatarFallback>
                      </Avatar>
                      {viewer?.profile?.firstName}
                    </AccordionTrigger>
                    <AccordionContent className="flex flex-col gap-y-8 pb-0 pl-4 pt-10 justify-start items-start">
                      <SheetClose asChild>
                        <HeaderNavLink to={'/users/' + viewer.id}>
                          {t('nav-my-profile')}
                        </HeaderNavLink>
                      </SheetClose>
                      <SheetClose asChild>
                        <HeaderNavLink to="/account">{t('nav-my-account')}</HeaderNavLink>
                      </SheetClose>
                      <button
                        type="button"
                        className={cn(
                          buttonVariants({ variant: 'link' }),
                          'text-red hover:text-red px-0 no-underline',
                          props.className,
                        )}
                        style={{
                          fontSize: getResponsiveFontSize({
                            minFontSize: 1,
                            maxFontSize: 1.25,
                          }),
                        }}
                        onClick={() => submit(null, { method: 'post', action: '/logout' })}
                      >
                        {t('nav-logout')}
                      </button>
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              ) : (
                <div className="flex flex-col-reverse flex-1 gap-4">
                  <HeaderNavLink to="/login" className={buttonVariants()}>
                    {t('nav-login')}
                  </HeaderNavLink>
                  <HeaderNavLink to="/signup">{t('nav-signup')}</HeaderNavLink>
                </div>
              )}
            </SheetFooter>
          </SheetPrimitive.Content>
        </SheetPrimitive.Portal>
      </Sheet>
    </nav>
  );
}

function HeaderNavLink(props: React.ComponentProps<typeof NavLink>) {
  return (
    <NavLink
      {...props}
      prefetch="intent"
      className={cn(buttonVariants({ variant: 'link' }), props.className, 'px-0 no-underline')}
      style={{
        fontSize: getResponsiveFontSize({
          minFontSize: 1,
          maxFontSize: 1.25,
        }),
      }}
    />
  );
}

function DesktopServicesNavItem() {
  const { t } = useTranslation();
  const submenu = React.useContext(HeaderContext).submenu;
  const [boundingRect, setBoundingRect] = React.useState({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  });
  const { width } = useWindowSize();

  React.useEffect(() => {
    if (width) {
      const header = document.getElementById('HEADER');

      if (header) setBoundingRect(header.getBoundingClientRect());
    }
  }, [width]);

  return (
    <Popover onOpenChange={submenu[1]} open={submenu[0]}>
      <PopoverTrigger asChild onMouseEnter={() => requestAnimationFrame(() => submenu[1](true))}>
        <li className="group">
          <HeaderNavLink to="#">
            {t('nav-services')}
            <ArrowDropdown
              className={cn('size-5 transition group-aria-[expanded=true]:rotate-180')}
            />
          </HeaderNavLink>
        </li>
      </PopoverTrigger>
      <PopoverContent
        align="end"
        style={{
          width: boundingRect.width + 'px',
          marginLeft: boundingRect.x + 'px',
          marginTop: boundingRect.y + 10 + 'px',
        }}
        className="grid grid-cols-4 gap-6 rounded-b-2xl rounded-t-none border-none px-6 pb-14 shadow-none"
        onMouseLeave={() => submenu[1](false)}
        onClick={() => submenu[1](false)}
      >
        <DesktopServicesNavSubItem
          title={t('activity-club')}
          description={t('activity-club-description')}
          image="/images/nav-activities-club.jpeg"
          to="/activites-senior"
        />

        <DesktopServicesNavSubItem
          title={t('house-sharing')}
          description={t('house-sharing-description')}
          image="/images/nav-house-sharing.jpeg"
          to={HOUSESHARING_LINK}
          target="_blank"
        />

        <DesktopServicesNavSubItem
          title={t('discount')}
          description={t('discount-description')}
          image="/images/nav-partners.jpeg"
          to={EXPERTS_APP_LINK}
          target="_blank"
        />
      </PopoverContent>
    </Popover>
  );
}

function DesktopServicesNavSubItem(
  props: {
    title: string;
    description: React.ReactNode;
    image: string;
  } & React.ComponentProps<typeof Link>,
) {
  const { t } = useTranslation();

  return (
    <Link {...props} className={cn('group flex flex-col', props.className)}>
      <ImageComp
        src={props.image}
        alt={props.title}
        height={214}
        className="mb-6 block h-[214px] w-full rounded-lg object-cover"
        style={{ imageRendering: '-webkit-optimize-contrast' }}
      />

      <span className="flex flex-col gap-y-6 flex-1">
        <HeadingS>{props.title}</HeadingS>

        <p
          className={textVariants({
            size: 'm',
            className: 'text-subdued-foreground font-medium',
          })}
        >
          {props.description}
        </p>

        <Button
          type="button"
          variant="link"
          className="no-underline focus-visible:ring-0 px-0 pointer-events-none mt-auto justify-self-end self-start"
        >
          {t('discover')} <ArrowRight className="size-5" />
        </Button>
      </span>
    </Link>
  );
}

function MobileServicesNavItem() {
  const { t } = useTranslation();
  return (
    <Accordion type="single" collapsible className="w-full">
      <AccordionItem value="services" className="m-0 border-none">
        <AccordionTrigger
          className={cn(buttonVariants({ variant: 'link' }), 'justify-start px-0 no-underline')}
          style={{
            fontSize: getResponsiveFontSize({
              minFontSize: 1,
              maxFontSize: 1.25,
            }),
          }}
        >
          {t('nav-services')}
        </AccordionTrigger>
        <AccordionContent className="flex flex-col gap-y-6 pb-0 pl-4 pt-10">
          <SheetClose asChild>
            <HeaderNavLink className="justify-start" to="/activites-senior">
              {t('activity-club')}
            </HeaderNavLink>
          </SheetClose>
          <SheetClose asChild>
            <HeaderNavLink className="justify-start" to={HOUSESHARING_LINK}>
              {t('house-sharing')}
            </HeaderNavLink>
          </SheetClose>
          <SheetClose asChild>
            <HeaderNavLink className="justify-start" to={EXPERTS_APP_LINK}>
              {t('discount')}
            </HeaderNavLink>
          </SheetClose>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}

function ViewerCoins({ viewer }: { viewer: ViewerSession }) {
  return (
    <Dialog>
      <DialogTrigger
        className={cn(
          'flex items-center gap-1 rounded-full border-2 bg-background p-2 font-black',
          textVariants({ size: 'm' }),
        )}
      >
        <Coin />
        <span className="block px-1">
          {new Intl.NumberFormat('fr-FR').format((viewer?.account?.balance || 0) / 100)}
        </span>
      </DialogTrigger>
      <DialogContent className="sm:max-w-screen-md max-h-[calc(100vh_-_20%)] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Fonctionnement des crédits</DialogTitle>
        </DialogHeader>

        <div
          className={textVariants({ size: 'paragraph-expanded', class: 'font-medium space-y-3' })}
        >
          <p>
            Les crédits vous servent à réserver une place pour une activité, seulement si celle-ci
            ne nécessite pas de paiement préalable. Chaque membre de Colette reçoit 3 crédits à la
            création de son compte.
          </p>

          <p>
            Ces crédits vous permettent de vous inscrire à vos premières activités. Une activité
            peut valoir 0, 1 ou 3 crédits. Par exemple :
          </p>

          <ul className="list-disc list-inside">
            <li>Une morning routine = 0 crédit</li>
            <li>Un cours de danse = 1 crédit</li>
          </ul>

          <HeadingXS className="pt-6">Comment conserver ses crédits ?</HeadingXS>
          <p>
            Il vous suffit de participer à l'activité à laquelle vous vous êtes inscrit.e pour
            récupérer ce crédit et maintenir votre cagnotte dans un solde de crédit positif. Vous
            récupérez ce crédit après l'activité, une fois que le capitaine de l'activité nous aura
            communiqué le nom des personnes présentes. Aussi, veillez bien à ce que le capitaine de
            l'activité note votre présence 👀
          </p>

          <HeadingXS className="pt-6">Comment perdre des crédits ?</HeadingXS>
          <p>
            Si vous ne vous présentez pas à une activité à laquelle vous vous êtes inscrit·e - en
            d'autres termes, si vous posez un lapin - vous perdez un crédit. Pas de panique ! En cas
            d'empêchement, vous pouvez vous désinscrire de l'activité avant qu'elle n'ait lieu.
            Ainsi, vous ne perdrez pas votre crédit et un autre membre de Colette pourra bénéficier
            de cette place.
          </p>

          <p>
            Pour se désinscrire d'une activité, vous devez vous rendre sur l'application ou le site
            internet. Il faut ensuite vous rendre sur l'activité et vous n'aurez plus qu'à cliquer
            sur le bouton "Se désinscrire". C'est très simple !
          </p>

          <p>Voici un exemple pour mieux comprendre :</p>

          <p>
            Je possède 3 crédits sur mon profil et je m'inscris à l'activité “Visite guidée” qui
            vaut 1 crédit. Il me reste donc 2 crédits suite à mon inscription. Plusieurs options :
          </p>
          <ul className="list-disc list-inside">
            <li>
              Je me présente à l'activité “Visite guidée” : je récupère mon crédit. J'ai de nouveau
              3 crédits.
            </li>
            <li>
              J'ai un empêchement et je me désinscris de l'activité “Visite guidée” avant qu'elle
              n'ait lieu : je récupère mon crédit. J'ai de nouveau 3 crédits.
            </li>
            <li>
              Je ne me présente pas à l'activité “Visite guidée” sans me désinscrire au préalable :
              Je ne récupère pas mon crédit utilisé pour l'activité et je perds en plus 1 crédit de
              pénalité. Le solde de ma cagnotte passe donc à 1 crédit !
            </li>
          </ul>

          <p>
            Que faire si je n'ai plus de crédit ? Le système a été pensé pour que cette situation ne
            puisse pas arriver tant que vous participez aux activités auxquelles vous vous inscrivez
            ou que vous pensez à bien vous désinscrire d'une activité en cas d'empêchement.
          </p>

          <p>
            Bien sûr, il peut toujours y avoir des exceptions en cas de force majeure. Vous savez
            que nous sommes attentifs à vous et à votre satisfaction. Auquel cas n'hésitez pas à
            nous écrire à{' '}
            <Link className="underline underline-offset-2" to={`mailto:${EMAIL}`}>
              {EMAIL}
            </Link>{' '}
            ou à nous appeler au{' '}
            <Link className="underline underline-offset-2" to={`tel:${PHONE_INTERNATIONAL}`}>
              {PHONE}
            </Link>
            , nous en discuterons.
          </p>

          <HeadingXS className="pt-6">
            Pourquoi mettez-vous en place ce système de crédits ?
          </HeadingXS>
          <p>
            Notre belle communauté s'agrandit de jours en jours et le Club grandit et évolue avec
            elle. Nous sommes très heureux de voir l'engouement autour de nos activités et les
            rencontres qu'elles créent. Et ce n'est que le début ! À travers ce sytème de crédits,
            nous souhaitons valoriser l'engagement de nos membres dans leur participation aux
            activités et restaurer les bonnes pratiques : lorsqu'on s'inscrit, on participe, ou
            sinon on prévient à l'avance pour laisser la place aux autres ! 😉
          </p>
        </div>
      </DialogContent>
    </Dialog>
  );
}
